import {configureStore} from '@reduxjs/toolkit'
import { authReducer } from './slices/auth';
import { slidersReducer } from './slices/sliders';
import { configuratorsReducer } from './slices/configurators';
const store = configureStore({
    reducer:{
        auth: authReducer,
        sliders: slidersReducer,
        configurators: configuratorsReducer
    }
});

export default store ;