import urls from "../../config/Config";

export const fetchCases = async () => {
    let token = localStorage.getItem('token');
    try {
        const response = await fetch(`${urls.API_BASE_URL}/cases`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return await response.json();
    } catch (error) {
        console.error('Error during fetch:', error);
        throw error;
    }
};

export const deleteAllCases = async () => {
    let token = localStorage.getItem('token');
    try {
        const response = await fetch(`${urls.API_BASE_URL}/cases`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return await response.json();
    } catch (error) {
        console.error('Error during fetch:', error);
        throw error;
    }
};

export const importCases = async () => {
    let token = localStorage.getItem('token');
    try {
        const response = await fetch(`${urls.API_BASE_URL}/cases/import`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return await response.json();
    } catch (error) {
        console.error('Error during fetch:', error);
        throw error;
    }
};

export const exportCases = async () => {
    let token = localStorage.getItem('token');
    try {
        const response = await fetch(`${urls.API_BASE_URL}/cases/export`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return await response.json();
    } catch (error) {
        console.error('Error during fetch:', error);
        throw error;
    }
};

export const addCase = async (body) => {
    let token = localStorage.getItem('token');
    try {
        const response = await fetch(`${urls.API_BASE_URL}/cases`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(body)
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return await response.json();
    } catch (error) {
        console.error('Error during fetch:', error);
        throw error;
    }
};
