import React from 'react';
import { List } from 'antd';

function TemplateList({ templates, selectedTemplateId, onSelectTemplate }) {
  return (
    <div style={{ padding: '8px' }}>
      <h3>Список шаблонов</h3>
      <List
        itemLayout="horizontal"
        dataSource={templates}
        renderItem={item => {
          return (
            <List.Item
              style={{
                cursor: 'pointer',
                backgroundColor:
                  item.id === selectedTemplateId
                    ? '#e6f7ff'
                    : 'transparent',
                marginBottom: '4px',
              }}
              onClick={() => onSelectTemplate(item.id)}
            >
              <List.Item.Meta
                avatar={<img src={item.preview} alt="preview" width="50" />}
                title={item.name}
              />
            </List.Item>
          );
        }}
      />
    </div>
  );
}

export default TemplateList;
