import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Result, Skeleton, Modal, Form, Input, message, Table, Popconfirm, Select } from 'antd';
import { fetchConfiguratorsByUser } from '../../redux/slices/configurators';
import { Link } from 'react-router-dom';
import { fetchAddConfigurator } from '../../apicalls/configurators/addConfigurator';
import { fetchDeleteConfigurator } from '../../apicalls/configurators/deleteConfigurator';


const ConfiguratorList = () => {
    const dispatch = useDispatch();
    const configurators = useSelector(state => state.configurators.configurators);
    const status = useSelector(state => state.configurators.status);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();

    useEffect(() => {
        if (status === 'idle') {
            dispatch(fetchConfiguratorsByUser());
        }
    }, [status, dispatch]);

    const showModal = (type) => {
        setIsModalOpen(type);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const createConfigurator = (value) => {

        fetchAddConfigurator(value, (data) => {
            if (data === false) {
                setIsModalOpen(false);
                messageApi.open({
                    type: 'error',
                    content: 'Toto je chybová zpráva',
                });
            } else if (data && data.id) {
                setIsModalOpen(false);
                messageApi.open({
                    type: 'success',
                    content: 'Toto je zpráva o úspěchu',
                });
                dispatch(fetchConfiguratorsByUser());
            }
        });
    };

    const deleteConfigurator = (id) => {
        fetchDeleteConfigurator(id, (data) => {
            if (data !== false) {
                messageApi.open({
                    type: 'success',
                    content: 'Toto je zpráva o úspěchu',
                });
                dispatch(fetchConfiguratorsByUser());
            } else {
                messageApi.open({
                    type: 'error',
                    content: 'Toto je chybová zpráva',
                });
            }
        });
    };

    if (status === 'loading') return <Skeleton active />;
    if (status === 'failed') return <p>Chyba při načítání konfigurátorů.</p>;

    const fakeData = [
        {
            id: 1,
            name: 'Konfigurátor 1',
            extra_info: {
                description: 'Popis konfigurátoru 1',
                type: 'change',
            },
            settings: {
                link: 'http://example.com/1',
                selector: '#selector1',
            },
        },
        {
            id: 2,
            name: 'Konfigurátor 2',
            extra_info: {
                description: 'Popis konfigurátoru 2',
                type: 'create',
            },
            settings: {
                link: 'http://example.com/2',
                selector: '#selector2',
            },
        },
    ];

    const columns = [
        {
            title: 'Název konfigurátoru',
            dataIndex: 'name',
            key: 'name',
            width: '25%',
            render: (text, record) => <Link to={`/configurator/${record.id}`}>{text}</Link>,
        },
        {
            title: 'Popis konfigurátoru',
            dataIndex: ['extra_info', 'description'],
            key: 'description',
        },
        {
            title: '',
            key: 'actions',
            width: '15%',
            render: (text, record) => (
                <div style={{ display: "flex", justifyContent: 'end' }}>
                    <Link to={`/configurator/${record.id}`}>
                        <Button type="primary">Nastavit</Button>
                    </Link>
                    <Popconfirm
                        title="Smazat konfigurátor"
                        description="Jste si jistí, že chcete smazat tento konfigurátor?"
                        okText="Ano"
                        cancelText="Ne"
                        onConfirm={() => deleteConfigurator(record.id)}
                    >
                        <Button type="primary" danger style={{ marginLeft: 8 }}>
                            Smazat
                        </Button>
                    </Popconfirm>
                </div>
            ),
        },
    ];

    return (
        <>
            {contextHolder}
            <Modal title="Přidat konfigurátor" open={isModalOpen} footer={false} onCancel={handleCancel}>
                <Form
                    name="basic"
                    onFinish={createConfigurator}
                    autoComplete="off"
                    layout='vertical'
                >
                    <Form.Item
                        label="Jméno"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: 'Prosím vložte jméno konfigurátoru!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Typ konfigurátoru"
                        name={["extra_info", "type"]}
                        rules={[
                            {
                                required: true,
                                message: 'Prosím zvolte typ konfigurátoru!',
                            },
                        ]}
                    >
                    <Select
                        style={{
                            width: "100%",
                        }}
                        options={[
                            {
                                value: 'change',
                                label: 'Změnit stávající konfigurátor',
                            },
                            {
                                value: 'create',
                                label: 'Vytvořit nový konfigurátor',
                                disabled: true,
                            }, 
                        ]}
                    />
                      </Form.Item>
                    <Form.Item
                        label="Odkaz"
                        name={['settings', 'link']}
                        rules={[
                            {
                                required: true,
                                message: 'Prosím vložte odkaz!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Selector"
                        name={['settings', 'selector']}
                        rules={[
                            {
                                required: true,
                                message: 'Prosím vložte selector!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                                        
                    <Form.Item name={["extra_info", "description"]} label="Popis">
                        <Input.TextArea />
                    </Form.Item>
                    <Form.Item
                        wrapperCol={{
                            offset: 8,
                            span: 16,
                        }}
                    >
                        <Button type="primary" htmlType="submit">
                            Vytvořit
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
            <div>
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                    <Button onClick={showModal} type="primary">Přidat konfigurátor</Button>
                </div>
                {fakeData.length > 0 ? (
                    <Table
                        dataSource={fakeData}
                        columns={columns}
                        rowKey="id"
                    />
                ) : (
                    <Result
                        status="404"
                        subTitle="Nemáte žádné konfigurátory"
                    />
                )}
            </div>
        </>
    );
};

export default ConfiguratorList;
