import React, { useRef, useEffect } from 'react';
import { Stage, Layer, Transformer } from 'react-konva';
import Element from './Element';

function CanvasArea({
  template,
  selectedElementId,
  onSelectElement,
  onUpdateElement,
}) {
  const stageRef = useRef(null);
  const transformerRef = useRef(null);

  const { width, height, items, backgroundColor } = template;

  useEffect(() => {
    const stage = stageRef.current;
    const transformer = transformerRef.current;
    if (!stage || !transformer) return;

    const selectedNode = stage.findOne(`#${selectedElementId}`);
    if (!selectedNode) {
      // нет выделенного элемента
      transformer.nodes([]);
      transformer.getLayer().batchDraw();
      return;
    }

    // проверим locked
    const selectedItem = items.find(i => i.id === selectedElementId);
    if (!selectedItem || selectedItem.locked) {
      // если locked => убираем Transformer
      transformer.nodes([]);
      transformer.getLayer().batchDraw();
      return;
    }

    // иначе привязываем
    transformer.nodes([selectedNode]);
    transformer.getLayer().batchDraw();
  }, [selectedElementId, items]);

  // Если клик по пустому месту, снимаем выделение
  const handleStageMouseDown = (e) => {
    if (e.target === e.target.getStage()) {
      onSelectElement(null);
    }
  };

  return (
    <div
      style={{
        border: '2px dashed #999',
        display: 'inline-block',
        height:'fit-content'
      }}
    >
      <Stage
        ref={stageRef}
        width={width}
        height={height}
        style={{
          backgroundColor: backgroundColor || '#ffffff',
          cursor: 'default',
        }}
        onMouseDown={handleStageMouseDown}
      >
        <Layer>
          {items.map((item) => (
            <Element
              key={item.id}
              item={item}
              onSelect={() => onSelectElement(item.id)}
              onChange={(newAttrs) => onUpdateElement(item.id, newAttrs)}
            />
          ))}
          <Transformer
            ref={transformerRef}
            enabledAnchors={[
              'top-left',
              'top-right',
              'middle-left',
              'middle-right',
              'bottom-left',
              'bottom-right',
              'bottom-center',
            ]}
            rotateAnchorOffset={60}
          />
        </Layer>
      </Stage>
    </div>
  );
}

export default CanvasArea;
