import React, { useState } from 'react';
import { Tabs } from 'antd';
import ProduktAndVariants from './ProduktAndVariants';
import TemplatesSetup from './TemplatesSetup';

const ConfiguratorMainSetup = () => {
    const [activeTabKey, setActiveTabKey] = useState('1');

    const changeTab = (activeKey) => {
        setActiveTabKey(activeKey);
    };

    const items = [
        {
            key: '1',
            label: 'Produkt a varianty',
            children: <ProduktAndVariants />,
        },
        {
            key: '2',
            label: 'Formulář pro nákup',
            children: <div>{/* Komponenta pro formulář pro nákup */}</div>,
        },
        {
            key: '3',
            label: 'Šablony vzhledu',
            children: <TemplatesSetup />,
        },
        {
            key: '4',
            label: 'Tiskový výstup',
            children: <div>{/* Komponenta pro tiskový výstup */}</div>,
        },
        {
            key: '5',
            label: 'Práce s obrázky',
            children: <div>{/* Komponenta pro práci s obrázky */}</div>,
        },
        {
            key: '6',
            label: 'Změna layoutu konfigurátoru',
            children: <div>{/* Komponenta pro změnu layoutu konfigurátoru */}</div>,
        },
    ];

    return (
        <Tabs
            activeKey={activeTabKey}
            onChange={changeTab}
            items={items}
        />
    );
};

export default ConfiguratorMainSetup;
