import React, { useRef, useEffect, useState } from 'react';
import { Text, Image } from 'react-konva';

function Element({ item, onSelect, onChange }) {
  const shapeRef = useRef(null);
  const [img, setImg] = useState(null);

  useEffect(() => {
    if (item.type === 'image' && item.src) {
      const image = new window.Image();
      image.src = item.src;
      image.onload = () => {
        setImg(image);
      };
    }
  }, [item.type, item.src]);

  // При завершении перетаскивания
  const handleDragEnd = (e) => {
    onChange({
      x: e.target.x(),
      y: e.target.y(),
    });
  };

  // При завершении трансформа
  const handleTransformEnd = () => {
    const node = shapeRef.current;
    const stage = node.getStage();

    const scaleX = node.scaleX();
    const scaleY = node.scaleY();

    const newAttrs = {
      x: node.x(),
      y: node.y(),
      rotation: node.rotation(),
    };

    if (item.type === 'image') {
      let newWidth = node.width() * scaleX;
      let newHeight = node.height() * scaleY;
      node.scaleX(1);
      node.scaleY(1);

      // упрощённый clamp, чтобы не выходить за пределы stage
      const stageW = stage.width();
      const stageH = stage.height();

      if (newWidth > stageW) {
        const ratio = stageW / newWidth;
        newWidth = stageW;
        newHeight *= ratio;
      }
      if (newHeight > stageH) {
        const ratio = stageH / newHeight;
        newHeight = stageH;
        newWidth *= ratio;
      }

      newAttrs.width = newWidth;
      newAttrs.height = newHeight;
    } else if (item.type === 'text') {
      const newFontSize = (item.fontSize || 20) * scaleX;
      node.scaleX(1);
      node.scaleY(1);

      const stageW = stage.width();
      let textWidth = node.width() * scaleX;
      if (textWidth > stageW) {
        const ratio = stageW / textWidth;
        newAttrs.fontSize = newFontSize * ratio;
      } else {
        newAttrs.fontSize = newFontSize;
      }
    }

    onChange(newAttrs);
  };

  // Если текст
  if (item.type === 'text') {
    return (
      <Text
        id={item.id}
        ref={shapeRef}
        text={item.text}
        x={item.x}
        y={item.y}
        rotation={item.rotation || 0}
        fontSize={item.fontSize || 20}
        fontFamily={item.fontFamily || 'Arial'}
        fill={item.fill || '#000000'}
        draggable={item.draggable !== false}
        onClick={onSelect}
        onTap={onSelect}
        onDragEnd={handleDragEnd}
        onTransformEnd={handleTransformEnd}
      />
    );
  }

  // Если картинка
  if (item.type === 'image') {
    return (
      <Image
        id={item.id}
        ref={shapeRef}
        image={img}
        x={item.x}
        y={item.y}
        rotation={item.rotation || 0}
        width={item.width || 150}
        height={item.height || 150}
        draggable={item.draggable !== false}
        onClick={onSelect}
        onTap={onSelect}
        onDragEnd={handleDragEnd}
        onTransformEnd={handleTransformEnd}
      />
    );
  }

  // Иначе ничего
  return null;
}

export default Element;
