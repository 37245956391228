import urls from "../../config/Config";

export const fetchConfiguratorsByUser = async () => {


    try {
        let token = localStorage.getItem('token');

        if(!token){
            return
        }
        const response = await fetch(`${urls.API_BASE_URL}/configurators`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                 'Authorization': `Bearer ${token}`
            }
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log('Response from Backend:', data);
        return data

    } catch (error) {
        console.error('Error during fetch:', error);
    }
};