import { ArrowLeftOutlined, CopyOutlined, DesktopOutlined, MobileOutlined, SaveOutlined, TabletOutlined } from '@ant-design/icons';
import { Button, Layout, message, Modal, Skeleton, Switch, Tabs } from 'antd';
import { cloneDeep, debounce } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import urls from '../../config/Config';
import food1 from "../../images/food1.jpg";
import food2 from "../../images/food2.jpg";
import food3 from "../../images/food3.jpg";
import food4 from "../../images/food4.jpg";
import food5 from "../../images/food5.jpg";
import food6 from "../../images/food6.jpg";
import food7 from "../../images/food7.jpg";
import food8 from "../../images/food8.jpg";
import food9 from "../../images/food9.jpg";
import { fetchSlidersByUser } from '../../redux/slices/sliders';
import { generateResponsiveCSS } from './generateCSS';
import ResponsiveSlider from './ResponsiveSlider';
import SliderEditorForm from './SliderEditorForm';

const { Content, Sider } = Layout;

const SliderEditor = () => {
  const iframeRef = useRef(null);
  const { id } = useParams();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.data);
  const sliders = useSelector(state => state.sliders.sliders);
  const [activeSlider, setActiveSlider] = useState(null);
  const [loading, setLoading] = useState(true);

  const [basicSettings, setBasicSettings] = useState(null);
  const [sliderDesktopSettings, setSliderDesktopSettings] = useState(null);
  const [sliderTabletSettings, setSliderTabletSettings] = useState(null);
  const [sliderMobileSettings, setSliderMobileSettings] = useState(null);
  const [activeTabKey, setActiveTabKey] = useState('1');
  const [sliderStyleDesktop, setSliderStyleDesktop] = useState(null);
  const [sliderStyleTablet, setSliderStyleTablet] = useState(null);
  const [sliderStyleMobile, setSliderStyleMobile] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showDemo, setShowDemo] = useState(true);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (!sliders || sliders.length === 0) {
      dispatch(fetchSlidersByUser());
    }
  }, [dispatch, sliders]);

  useEffect(() => {
    if (sliders && sliders.length > 0) {
      const currentSlider = sliders.find(slider => slider.id === id);
      if (currentSlider) {
        setActiveSlider(currentSlider);
        const { settings } = currentSlider;
        setBasicSettings({
          name: currentSlider.name,
          extra_info: {
            type: currentSlider.extra_info.type,
            description: currentSlider.extra_info.description
          },
          settings: {
            link: settings.link,
            selector: settings.selector
          }
        })
        setSliderDesktopSettings(settings.js);
        const tabletSettings = settings.js.responsive?.find(res => res.breakpoint === 1200)?.settings;
        const mobileSettings = settings.js.responsive?.find(res => res.breakpoint === 768)?.settings;
        setSliderTabletSettings(tabletSettings || null);
        setSliderMobileSettings(mobileSettings || null);

        if (settings.definitionCSS) {
          if (settings.definitionCSS.sliderStyleDesktop) {
            setSliderStyleDesktop(settings.definitionCSS.sliderStyleDesktop);
          }
          setSliderStyleTablet(settings.definitionCSS.sliderStyleTablet || null);
          setSliderStyleMobile(settings.definitionCSS.sliderStyleMobile || null);
        }
        setLoading(false);
      }
    }
  }, [sliders, id]);


  const changeBasicSettings = (values) => {
    setBasicSettings(prevState => ({
      ...prevState,
      ...values,
      extra_info: {
        ...prevState.extra_info,
        ...values.extra_info
      },
      settings: {
        ...prevState.settings,
        ...values.settings
      }
    }));
  };
  const changeSliderSettings = (values) => {
    switch (activeTabKey) {
      case '1':
        setSliderDesktopSettings(values);
        break;
      case '2':
        setSliderTabletSettings(values);
        break;
      case '3':
        setSliderMobileSettings(values);
        break;
      default:
        break;
    }
  };

  const changeTab = (activeKey) => {
    setActiveTabKey(activeKey);
  };

  const handleSliderStyleChange = useCallback(
    debounce((changedValues, values) => {
      const keys = Object.keys(changedValues);
      if (keys[0] === 'leftButtonImageUrl' || keys[0] === 'rightButtonImageUrl') {
        return
      }
      switch (activeTabKey) {
        case '1':
          setSliderStyleDesktop(values);
          break;
        case '2':
          setSliderStyleTablet(values);
          break;
        case '3':
          setSliderStyleMobile(values);
          break;
        default:
          break;
      }
    }, 300),
    [activeTabKey]
  );

  const reloadIframe = () => {
    if (iframeRef.current) {
      const src = iframeRef.current.src;
      iframeRef.current.src = '';
      setTimeout(() => {
        iframeRef.current.src = src;
      }, 10);
    }
  };

  const saveChanges = async () => {
    const js = { ...sliderDesktopSettings };
    js.responsive = [];
    if (sliderTabletSettings) {
      js.responsive.push({ breakpoint: 1200, settings: sliderTabletSettings });
    }
    if (sliderMobileSettings) {
      js.responsive.push({ breakpoint: 768, settings: sliderMobileSettings });
    }
    const updatedSlider = {
      // ...activeSlider,
      name: basicSettings.name,
      extra_info: {
        // ...activeSlider.extra_info,
        ...basicSettings.extra_info
      },
      settings: {
        // ...activeSlider.settings,
        js,
        link: basicSettings.settings.link,
        selector: basicSettings.settings.selector,
        definitionCSS: {
          sliderStyleDesktop,
          sliderStyleTablet,
          sliderStyleMobile
        },
        css: generateResponsiveCSS(sliderStyleDesktop, sliderStyleTablet, sliderStyleMobile)
      }
    };

    try {
      const formData = new FormData();
      formData.append('sliderData', JSON.stringify(updatedSlider));
      formData.append('baseUrl', urls.API_BASE_URL);

      const response = await fetch(`${urls.API_BASE_URL}/update-slider/${id}`, {
        method: 'PUT',
        body: formData,
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
      });

      if (response.ok) {
        message.success('Slider byl úspěšně aktualizován!');
        dispatch(fetchSlidersByUser());
        reloadIframe();
      } else {
        message.error('Chyba při aktualizaci slideru.');
      }
    } catch (error) {
      message.error('Chyba při aktualizaci slideru.');
      console.error(error);
    }
  };
  const changeShowDemo = (checked) => {
    setShowDemo(checked);
  }
  const showMyEshop = () => {
    window.open(activeSlider.settings.link, 'Example', 'width=800,height=600');
  }

  const handleFileUpload = (file, name) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('buttonType', file.name);

    fetch(`${urls.API_BASE_URL}/upload-slider-image/${id}`, {
      method: 'POST',
      body: formData,
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
    })
      .then(response => response.json())
      .then(data => {
        switch (activeTabKey) {
          case '1':
            setSliderStyleDesktop(prev => ({ ...prev, [name]: data.url }));
            break;
          case '2':
            if (sliderStyleTablet) {
              setSliderStyleTablet(prev => ({ ...prev, [name]: data.url }));
            }
            else {
              let newSliderStyleTablet = cloneDeep(sliderStyleDesktop);
              newSliderStyleTablet[name] = data.url;
              setSliderStyleTablet(newSliderStyleTablet);
            }
            break;
          case '3':
            if (sliderStyleMobile) {
              setSliderStyleMobile(prev => ({ ...prev, [name]: data.url }));
            }
            else {
              let newSliderStyleMobile = cloneDeep(sliderStyleDesktop);
              newSliderStyleMobile[name] = data.url;
              setSliderStyleMobile(newSliderStyleMobile);
            }
            break;
          default:
            break;
        }
      })
      .catch(error => {
        console.error('Error uploading file:', error);
      });
  }

  const handleFileRemove = (name) => {
    switch (activeTabKey) {
      case '1':
        setSliderStyleDesktop(prev => ({ ...prev, [name]: '' }));
        break;
      case '2':
        setSliderStyleTablet(prev => ({ ...prev, [name]: '' }));
        break;
      case '3':
        setSliderStyleMobile(prev => ({ ...prev, [name]: '' }));
        break;
      default:
        break;
    }
  }
  const copyValue = (value) => {
    function fallbackCopyTextToClipboard(text) {
      var textArea = document.createElement("textarea");
      textArea.value = text;
      // Avoid scrolling to bottom
      textArea.style.top = "0";
      textArea.style.left = "0";
      textArea.style.position = "fixed";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        var successful = document.execCommand('copy');
        var msg = successful ? 'successful' : 'unsuccessful';
        console.log('Fallback: Copying text command was ' + msg);
      } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
      }
      document.body.removeChild(textArea);
    }

    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(value);
      return;
    }
    navigator.clipboard.writeText(value).then(function () {
      message.success('Zkopírováno');
      console.log('Async: Copying to clipboard was successful!');
    }, function (err) {
      console.error('Async: Could not copy text: ', err);
    });
  }

  if (loading) {
    return <Skeleton active />;
  }
  console.log(sliderStyleDesktop);

  const images = [food1, food2, food3, food4, food5, food6, food7, food8, food9];
  let sliderSettings;
  let maxWidth;
  let sliderStyle;
  switch (activeTabKey) {
    case '1':
      sliderSettings = sliderDesktopSettings;
      sliderStyle = sliderStyleDesktop;
      maxWidth = '95%';
      break;
    case '2':
      sliderSettings = sliderTabletSettings || sliderDesktopSettings;
      sliderStyle = sliderStyleTablet || sliderStyleDesktop;
      maxWidth = '1199px';
      break;
    case '3':
      sliderSettings = sliderMobileSettings || sliderDesktopSettings;
      sliderStyle = sliderStyleMobile || sliderStyleDesktop;
      maxWidth = '767px';
      break;
    default:
      sliderSettings = sliderDesktopSettings;
      sliderStyle = sliderStyleDesktop;
  }
  const items = [
    {
      key: '1',
      label: <div style={{ textAlign: 'center' }}><DesktopOutlined style={{ fontSize: '32px' }} /><div>{'>1200'}</div></div>,
      children: <SliderEditorForm
        basicSettings={basicSettings}
        changeBasicSettings={changeBasicSettings}
        // title='Desktop settings'
        title='Nastavení pro desktop'
        sliderStyle={sliderStyle}
        sliderSettings={sliderDesktopSettings}
        handleSliderStyleChange={handleSliderStyleChange}
        handleSubmit={changeSliderSettings}
        handleFileUpload={handleFileUpload}
        handleFileRemove={handleFileRemove} />,
    },
    {
      key: '2',
      label: <div style={{ textAlign: 'center' }}><TabletOutlined style={{ fontSize: '32px' }} /><div>{'<1200px'}</div></div>,
      children: <SliderEditorForm
        basicSettings={basicSettings}
        changeBasicSettings={changeBasicSettings}
        // title='Tablet settings'
        title='Nastavení pro tablet'
        sliderStyle={sliderStyle}
        sliderSettings={sliderSettings}
        handleSliderStyleChange={handleSliderStyleChange}
        handleSubmit={changeSliderSettings}
        handleFileUpload={handleFileUpload}
        handleFileRemove={handleFileRemove} />,
    },
    {
      key: '3',
      label: <div style={{ textAlign: 'center' }}><MobileOutlined style={{ fontSize: '24px' }} /><br />{'<768px'}</div>,
      children: <SliderEditorForm
        basicSettings={basicSettings}
        changeBasicSettings={changeBasicSettings}
        // title='Mobile settings'
        title='Nastavení pro mobil'
        sliderStyle={sliderStyle}
        sliderSettings={sliderSettings}
        handleSliderStyleChange={handleSliderStyleChange}
        handleSubmit={changeSliderSettings}
        handleFileUpload={handleFileUpload}
        handleFileRemove={handleFileRemove} />,
    },
  ];
  //     <p><code>{`<script src="${urls.API_BASE_URL}/static/js/papcraftSliderJS.js"></script> `}</code></p>
  //  <p><code>{`<link rel="stylesheet" type="text/css" href="${urls.API_BASE_URL}/static/css/papcraftSliderCss.css" />`}</code></p>
  const modalBody = (
    <div>
      <h2 style={{ textAlign: 'center', }}>Záhlaví (před koncovým tagem HEAD)</h2>
      <p><code>{`<link rel="stylesheet" type="text/css" href="${urls.FILES_BASE_URL}/files/${user.id}/sliders/${id}/slider.css" /> `}</code>
        <Button
          title={'Kopírovat'}
          onClick={() => copyValue(`<link rel="stylesheet" type="text/css" href="${urls.FILES_BASE_URL}/files/${user.id}/sliders/${id}/slider.css" /> `)}
          type="link" ><CopyOutlined />
        </Button>
      </p>
      <h2 style={{ textAlign: 'center', marginTop: '20px' }}>Zápatí (před koncovým tagem BODY)</h2>
      <p><code>{` <script src="${urls.FILES_BASE_URL}/files/${user.id}/sliders/${id}/slider.js"></script>`}</code>
        <Button
          title={'Kopírovat'}
          onClick={() => copyValue(` <script src="${urls.FILES_BASE_URL}/files/${user.id}/sliders/${id}/slider.js"></script>`)}
          type="link" ><CopyOutlined />
        </Button>
      </p>
      {/* <h2 style={{ textAlign: 'center', marginTop:'20px' }}>Pokud není připojeno jQuery nebo si nejste jistí, přidejte tento řádek též do zápatí</h2> */}
      {/* <p><code>{`<script src="https://code.jquery.com/jquery-3.6.0.min.js"></script> `}</code></p> */}
    </div>
  );
  console.log(activeSlider);
  if (activeSlider) {
    return (
      <>
        <Modal title="Vkládací kód" width={"60%"} open={isModalOpen} footer={false} onCancel={handleCancel}>
          {modalBody}
        </Modal>
        <div style={{ display: 'flex', justifyContent: "space-between", padding: "0px 10px 20px 10px" }}>
          <Button type='text'>
            <Link to='/sliders'>
              <ArrowLeftOutlined /> Zpět
            </Link>
          </Button>
          <div >
            <span style={{ marginRight: '10px' }}>Zobrazit demo <Switch defaultChecked onChange={changeShowDemo} /></span>
            <Button type='primary' onClick={showMyEshop} style={{ marginRight: "10px" }}>
              Ukázat můj E-shop
            </Button>
            <Button type='primary' onClick={showModal} style={{ marginRight: "10px" }}>
              Vkládací kód
            </Button>

            <Button type='primary' onClick={saveChanges}>
              <SaveOutlined />Uložit změny
            </Button>
          </div>
        </div>
        <Layout>
          <Sider theme="light" width={showDemo ? '500' : '100%'}>
            <Tabs
              activeKey={activeTabKey}
              type="card"
              defaultActiveKey="1"
              items={items}
              onChange={changeTab}
              tabPosition="bottom"
            />
          </Sider>

          {showDemo && <Content style={{ display: 'flex', justifyContent: 'center' }}>
            <ResponsiveSlider maxWidth={maxWidth} sliderStyle={sliderStyle} images={images} defaultSettings={sliderSettings} />
          </Content>}
        </Layout>
      </>
    );
  }


}

export default SliderEditor;
