import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Card, Row, Tag, } from 'antd';
import './pluginList.css'
import swipe from '../img/swipe.png'
import computer from '../img/computer.png'
const { Meta } = Card;
const PluginList = () => {
    const user = useSelector(state => state.auth.data);

    if (!user) {
        return <h1>Please log in to view plugins</h1>;
    }

    // Рендер карточек плагинов на основе доступных плагинов у пользователя
    const renderPluginCards = () => {
        if (!user.extra_info || !user.extra_info.plugins) {
            return <p>No plugins available.</p>;
        }
        let pl = ['slider']
        return user.extra_info.plugins.map((plugin, index) => {
            let pluginContent;

            switch (plugin) {
                case 'slider':
                    pluginContent = (
                        <Card
                        style={{
                            width: 500,
                            marginTop: 16,
                        }}
                        extra={<Link style={{ color: "#fff", background: "#1677ff", padding: "4px 15px", borderRadius: '6px' }} to="/sliders">Zobrazit</Link>}
                    >
                        {/* <Skeleton loading={loading} avatar active> */}
                        <Meta
                            avatar={<img alt="example" src={swipe} width={"150px"} />}
                            title={<h2>Slider na míru</h2>}
                            description={<div className="slider-description">
                                <p><strong>S naším pluginem si můžete přizpůsobit slider přesně podle vašich potřeb. Máte možnost regulovat:</strong></p>
                                <Tag color="blue" style={{ margin: '2px' }}>Rychlost přechodu</Tag>
                                <Tag color="blue" style={{ margin: '2px' }}>Typ animace</Tag>
                                <Tag color="blue" style={{ margin: '2px' }}>Adaptivní zobrazení</Tag>
                                <Tag color="blue" style={{ margin: '2px' }}>Automatické přehrávání</Tag>
                                <Tag color="blue" style={{ margin: '2px' }}>Пřizpůsobitelné prvky</Tag>
                                <Tag color="blue" style={{ margin: '2px' }}>...</Tag>
                            </div>}
                        />
                        {/* </Skeleton> */}
                    </Card>
                    );
                    break;
                case 'configurator':
                    pluginContent = (
                        <Card
                    style={{
                        width: 500,
                        marginTop: 16,
                    }}
                    extra={<Link style={{ color: "#fff", background: "#1677ff", padding: "4px 15px", borderRadius: '6px' }} to="/configurators">Zobrazit</Link>}
                >
                    <Meta
                        avatar={<img alt="example" src={computer} width={"150px"} />}
                        title={<h2>Konfigurátor</h2>}
                        description={<div className="slider-description">
                            <p><strong>S naším pluginem si můžete přizpůsobit slider přesně podle vašich potřeb. Máte možnost regulovat:</strong></p>
                            <Tag color="blue" style={{ margin: '2px' }}>Rychlost přechodu</Tag>
                            <Tag color="blue" style={{ margin: '2px' }}>Typ animace</Tag>
                            <Tag color="blue" style={{ margin: '2px' }}>Adaptivní zobrazení</Tag>
                            <Tag color="blue" style={{ margin: '2px' }}>Automatické přehrávání</Tag>
                            <Tag color="blue" style={{ margin: '2px' }}>Přizpůsobitelné prvky</Tag>
                            <Tag color="blue" style={{ margin: '2px' }}>...</Tag>
                        </div>}
                    />
                </Card> 
                    );
                    break;
                default:
                    pluginContent = null;
                    break;
            }

            return pluginContent;
        });
    };

    return (
        <div>
            <h1 style={{ textAlign: 'center', margin: "20px" }}>Seznam pluginů</h1>
            <Row gutter={[16, 16]}>
                {renderPluginCards()}  
            </Row>
        </div>
    );
}

export default PluginList;
