import React, { useState, useEffect } from 'react';
import { Input, Button, Collapse } from 'antd';

function RightPanel({
  template,
  onSaveChanges,
  onUpdateElement,
  onAddElement,
}) {
  const [width, setWidth] = useState(template.width);
  const [height, setHeight] = useState(template.height);
  const [bgColor, setBgColor] = useState(template.backgroundColor || '#ffffff');

  useEffect(() => {
    setWidth(template.width);
    setHeight(template.height);
    setBgColor(template.backgroundColor || '#ffffff');
  }, [template]);

  // Сохраняем общий шаблон
  const handleSaveTemplate = () => {
    onSaveChanges({
      width,
      height,
      backgroundColor: bgColor,
      items: template.items,
    });
  };

  // Добавить текст
  const [newText, setNewText] = useState('');
  const handleAddText = () => {
    if (!newText.trim()) return;
    onAddElement({
      id: `elem-${Date.now()}`,
      type: 'text',
      text: newText,
      x: 50,
      y: 50,
      rotation: 0,
      fontSize: 20,
      fontFamily: 'Arial',
      fill: '#000000',
      draggable: true,
      locked: false,
    });
    setNewText('');
  };

  // Загрузка изображения
  const handleUploadImage = (e) => {
    const file = e.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onloadend = () => {
      const base64Data = reader.result;
      onAddElement({
        id: `elem-${Date.now()}`,
        type: 'image',
        src: base64Data,
        x: 100,
        y: 100,
        rotation: 0,
        width: 150,
        height: 150,
        draggable: true,
        locked: false,
      });
    };
    reader.readAsDataURL(file);
  };

  return (
    <div style={{ padding: '16px', height: '100%', overflow: 'auto' }}>
      <h3>Настройки шаблона</h3>

      <div style={{ marginBottom: '8px' }}>
        <span>Ширина:</span>
        <Input
          type="number"
          value={width}
          onChange={(e) => setWidth(Number(e.target.value))}
        />
      </div>

      <div style={{ marginBottom: '8px' }}>
        <span>Высота:</span>
        <Input
          type="number"
          value={height}
          onChange={(e) => setHeight(Number(e.target.value))}
        />
      </div>

      <div style={{ marginBottom: '8px' }}>
        <span>Фон холста:</span>
        <Input
          type="color"
          value={bgColor}
          onChange={(e) => setBgColor(e.target.value)}
        />
      </div>

      <Button
        type="primary"
        onClick={handleSaveTemplate}
        style={{ marginBottom: '16px' }}
      >
        Сохранить шаблон
      </Button>


       
          <div style={{ marginBottom: 8 }}>
            <span>Новый текст:</span>
            <Input
              placeholder="Введите текст"
              value={newText}
              onChange={(e) => setNewText(e.target.value)}
            />
          </div>
          <Button onClick={handleAddText} style={{ marginBottom: '8px' }}>
            Добавить текст
          </Button>

          <div style={{ marginTop: 8 }}>
            <span>Загрузить картинку:</span>
            <Input
              type="file"
              accept="image/*"
              onChange={handleUploadImage}
            />
          </div>
    </div>
  );
}

export default RightPanel;
