import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchConfiguratorsByUser as fetchConfiguratorsApi } from '../../apicalls/configurators/configuratorsByUser';


export const fetchConfiguratorsByUser = createAsyncThunk('configurators/fetchByUser', async () => {
    try {
        const data = await fetchConfiguratorsApi();
        return data;

    } catch (error) {
        console.error('Error during fetch:', error);
        throw error;
    }

});

const initialState = {
    sliders: [],
    status: 'idle',  // 'idle' | 'loading' | 'loaded' | 'error'
    error: null
};

const configuratorsSlice = createSlice({
    name: 'configurators',
    initialState,

    reducers: {},
    extraReducers: {
        [fetchConfiguratorsByUser.pending]: (state) => {
            state.status = 'loading';
        },
        [fetchConfiguratorsByUser.fulfilled]: (state, action) => {

            state.configurators = action.payload;
            state.status = 'loaded';

        },
        [fetchConfiguratorsByUser.rejected]: (state, action) => {
            state.error = action.error.message;
            state.status = 'error';
            state.configurators = [];
        }


    }
});

export  const configuratorsReducer = configuratorsSlice.reducer;
