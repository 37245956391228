import React, { useEffect, useState } from 'react';
import { Button, Table, message, Popconfirm } from 'antd';
import { fetchCases, deleteAllCases, importCases, exportCases, addCase } from '../../apicalls/configurators/cases';

const ProduktAndVariants = () => {
    const [cases, setCases] = useState([]);
    const [loading, setLoading] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();

    useEffect(() => {
        setLoading(true);
        fetchCases()
            .then(data => {
                setCases(data);
                setLoading(false);
            })
            .catch(error => {
                messageApi.open({
                    type: 'error',
                    content: 'Chyba při načítání případů',
                });
                setLoading(false);
            });
    }, []);

    const handleImport = () => {
        importCases()
            .then(() => {
                messageApi.open({
                    type: 'success',
                    content: 'Případy byly úspěšně importovány',
                });
                fetchCases().then(data => setCases(data));
            })
            .catch(error => {
                messageApi.open({
                    type: 'error',
                    content: 'Chyba při importování případů',
                });
            });
    };

    const handleExport = () => {
        exportCases()
            .then(() => {
                messageApi.open({
                    type: 'success',
                    content: 'Případy byly úspěšně exportovány',
                });
            })
            .catch(error => {
                messageApi.open({
                    type: 'error',
                    content: 'Chyba při exportování případů',
                });
            });
    };

    const handleDeleteAll = () => {
        deleteAllCases()
            .then(() => {
                messageApi.open({
                    type: 'success',
                    content: 'Všechny případy byly úspěšně smazány',
                });
                setCases([]);
            })
            .catch(error => {
                messageApi.open({
                    type: 'error',
                    content: 'Chyba při mazání případů',
                });
            });
    };

    const handleAddCase = () => {
        addCase()
            .then(newCase => {
                messageApi.open({
                    type: 'success',
                    content: 'Případ byl úspěšně přidán',
                });
                setCases([...cases, newCase]);
            })
            .catch(error => {
                messageApi.open({
                    type: 'error',
                    content: 'Chyba při přidávání případu',
                });
            });
    };

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Název',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Popis',
            dataIndex: 'description',
            key: 'description',
        },
    ];

    return (
        <>
            {contextHolder}
            <div style={{ marginBottom: 16 }}>
                <Button onClick={handleImport} type="primary" style={{ marginRight: 8 }}>Importovat případy (.CSV)</Button>
                <Button onClick={handleExport} type="primary" style={{ marginRight: 8 }}>Exportovat případy (.CSV)</Button>
                <Popconfirm
                    title="Smazat všechny případy"
                    description="Jste si jistí, že chcete smazat všechny případy?"
                    okText="Ano"
                    cancelText="Ne"
                    onConfirm={handleDeleteAll}
                >
                    <Button type="primary" danger style={{ marginRight: 8 }}>
                        Smazat všechny případy
                    </Button>
                </Popconfirm>
                <Button onClick={handleAddCase} type="primary">Přidat případ</Button>
            </div>
            <Table dataSource={cases} columns={columns} rowKey="id" loading={loading} />
        </>
    );
};

export default ProduktAndVariants;
