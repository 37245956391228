import React, { useState } from 'react';
import { Layout } from 'antd';
import TemplateList from './Templates/TemplateList';
import CanvasArea from './Templates/CanvasArea';
import RightPanel from './Templates/RightPanel';
import TopPanel from './Templates/TopPanel';

const { Sider, Content } = Layout;

function TemplatesSetup() {
  // Массив шаблонов
  const [templates, setTemplates] = useState([
    {
      id: 1,
      name: 'Шаблон 1',
      preview: 'https://via.placeholder.com/100',
      width: 800,
      height: 600,
      backgroundColor: '#ffffff',
      items: [
        {
          id: 'item-1',
          type: 'text',
          text: 'Привет, мир!',
          x: 50,
          y: 50,
          rotation: 0,
          fontSize: 24,
          fontFamily: 'Arial',
          fill: '#000000',
          draggable: true,
          locked: false,
        },
      ],
    },
    {
      id: 2,
      name: 'Шаблон 2',
      preview: 'https://via.placeholder.com/100',
      width: 800,
      height: 600,
      backgroundColor: '#ffffff',
      items: [
        {
          id: 'item-2',
          type: 'text',
          text: 'Привет, мир2!',
          x: 70,
          y: 70,
          rotation: 0,
          fontSize: 24,
          fontFamily: 'Arial',
          fill: '#000000',
          draggable: true,
          locked: false,
        },
      ],
    },
  ]);

  // Выбранный шаблон
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  // Выбранный элемент внутри шаблона
  const [selectedElementId, setSelectedElementId] = useState(null);

  // Текущий выбранный шаблон и элемент
  const selectedTemplate = templates.find(t => t.id === selectedTemplateId) || null;
  const selectedElement = selectedTemplate
    ? selectedTemplate.items.find(i => i.id === selectedElementId) || null
    : null;

  // == ФУНКЦИИ ==

  // Выбрать шаблон
  const handleSelectTemplate = (templateId) => {
    setSelectedTemplateId(templateId);
    setSelectedElementId(null); // сбрасываем выделение элемента
  };

  // Сохранить изменения всего шаблона (фон, размеры, items)
  const handleSaveChanges = (newData) => {
    if (!selectedTemplate) return;
    setTemplates(prev => prev.map(t => {
      if (t.id === selectedTemplate.id) {
        return { ...t, ...newData };
      }
      return t;
    }));
  };

  // Обновить атрибуты элемента
  const handleUpdateElement = (elementId, newAttrsOrFn) => {
    if (!selectedTemplate) return;
    setTemplates(prev => prev.map(template => {
      if (template.id !== selectedTemplate.id) return template;

      const updatedItems = template.items.map(item => {
        if (item.id !== elementId) return item;

        if (typeof newAttrsOrFn === 'function') {
          // Если передали колбэк (prev => ({ rotation: prev.rotation + 15 }))
          const changes = newAttrsOrFn(item);
          return { ...item, ...changes };
        } else {
          // Обычный объект
          return { ...item, ...newAttrsOrFn };
        }
      });
      return { ...template, items: updatedItems };
    }));
  };

  // Добавить элемент
  const handleAddElement = (elem) => {
    if (!selectedTemplate) return;
    const newItems = [...selectedTemplate.items, elem];
    handleSaveChanges({ items: newItems });
  };

  // Удалить элемент
  const handleDeleteElement = (elementId) => {
    if (!selectedTemplate) return;
    setTemplates(prev => prev.map(template => {
      if (template.id !== selectedTemplate.id) return template;
      const filtered = template.items.filter(item => item.id !== elementId);
      return { ...template, items: filtered };
    }));
    // Если удалили выделенный элемент – сбрасываем выделение
    if (elementId === selectedElementId) {
      setSelectedElementId(null);
    }
  };

  // Дублировать элемент
  const handleDuplicateElement = (elementId) => {
    if (!selectedTemplate) return;
    setTemplates(prev => prev.map(template => {
      if (template.id !== selectedTemplate.id) return template;
      const itemsCopy = [...template.items];
      const index = itemsCopy.findIndex(i => i.id === elementId);
      if (index === -1) return template;

      const original = itemsCopy[index];
      const newId = `elem-${Date.now()}`;
      const duplicated = {
        ...original,
        id: newId,
        x: original.x + 20,
        y: original.y + 20,
      };
      itemsCopy.push(duplicated);

      // Выделяем новый элемент
      setSelectedElementId(newId);

      return { ...template, items: itemsCopy };
    }));
  };

  // Перемещение "на один уровень выше"
  const handleMoveUpOne = (elementId) => {
    if (!selectedTemplate) return;
    setTemplates(prev => prev.map(template => {
      if (template.id !== selectedTemplate.id) return template;

      const itemsCopy = [...template.items];
      const index = itemsCopy.findIndex(i => i.id === elementId);
      // если уже в самом верху или не нашли
      if (index === -1 || index === itemsCopy.length - 1) return template;

      const temp = itemsCopy[index];
      itemsCopy[index] = itemsCopy[index + 1];
      itemsCopy[index + 1] = temp;
      return { ...template, items: itemsCopy };
    }));
  };

  // Перемещение "на один уровень ниже"
  const handleMoveDownOne = (elementId) => {
    if (!selectedTemplate) return;
    setTemplates(prev => prev.map(template => {
      if (template.id !== selectedTemplate.id) return template;

      const itemsCopy = [...template.items];
      const index = itemsCopy.findIndex(i => i.id === elementId);
      // если уже в самом низу
      if (index <= 0) return template;

      const temp = itemsCopy[index];
      itemsCopy[index] = itemsCopy[index - 1];
      itemsCopy[index - 1] = temp;
      return { ...template, items: itemsCopy };
    }));
  };

  return (
    <Layout style={{ height: '100vh' }}>
      {/* Слева: список шаблонов */}
      <Sider width={300} theme="light">
        <TemplateList
          templates={templates}
          selectedTemplateId={selectedTemplateId}
          onSelectTemplate={handleSelectTemplate}
        />
      </Sider>

      <Layout>
        {/* Сверху: TopPanel */}
        <TopPanel
          selectedElement={selectedElement}
          onUpdateElement={handleUpdateElement}
          onDeleteElement={handleDeleteElement}
          onDuplicateElement={handleDuplicateElement}
          onMoveUpOne={handleMoveUpOne}
          onMoveDownOne={handleMoveDownOne}
        />

        {/* Центр: CanvasArea */}
        <Content
          style={{
            padding: '16px',
            display: 'flex',
            justifyContent: 'center',

          }}
        >
          {selectedTemplate ? (
            <CanvasArea
              template={selectedTemplate}
              selectedElementId={selectedElementId}
              onSelectElement={setSelectedElementId}
              onUpdateElement={handleUpdateElement}
            />
          ) : (
            <div>Выберите шаблон</div>
          )}
        </Content>
      </Layout>

      {/* Справа: RightPanel */}
      <Sider width={300} theme="light">
        {selectedTemplate && (
          <RightPanel
            template={selectedTemplate}
            onSaveChanges={handleSaveChanges}
            onUpdateElement={handleUpdateElement}
            onAddElement={handleAddElement}
            // selectedElement можно удалить, если не нужно
          />
        )}
      </Sider>
    </Layout>
  );
}

export default TemplatesSetup;
