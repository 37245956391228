import React, { useState, useEffect } from 'react';
import { Button, Input, Select } from 'antd';
import {
  DeleteOutlined,
  CopyOutlined,
  UpOutlined,
  DownOutlined,
  LockOutlined,
  UnlockOutlined
} from '@ant-design/icons';

function TopPanel({
  selectedElement,
  onUpdateElement,
  onDeleteElement,
  onDuplicateElement,
  onMoveUpOne,
  onMoveDownOne,
}) {
  const [textValue, setTextValue] = useState('');
  const [fontFamily, setFontFamily] = useState('Arial');
  const [fontSize, setFontSize] = useState(20);
  const [fillColor, setFillColor] = useState('#000000');

  useEffect(() => {
    if (!selectedElement) return;
    // Если элемент — текст, синхронизируем локальные поля
    if (selectedElement.type === 'text') {
      setTextValue(selectedElement.text || '');
      setFontFamily(selectedElement.fontFamily || 'Arial');
      setFontSize(selectedElement.fontSize || 20);
      setFillColor(selectedElement.fill || '#000000');
    }
  }, [selectedElement]);

  if (!selectedElement) {
    // если элемент не выбран, пустая панель
    return (
      <div
        style={{
          display: 'flex',
          gap: 8,
          borderBottom: '1px solid #ccc',
          padding: 8,
          alignItems: 'center',
          height: 49,
        }}
      />
    );
  }

  // locked = true => замок «закрыт», нельзя двигать/трансформировать
  const locked = !!selectedElement.locked;
  const draggable = !!selectedElement.draggable;

  // При обновлении текста
  const handleChangeText = (changes) => {
    onUpdateElement(selectedElement.id, {
      ...selectedElement,
      ...changes,
    });
  };

  // Нажатие на замок: переключаем locked, draggable
  const toggleLock = () => {
    onUpdateElement(selectedElement.id, {
      ...selectedElement,
      locked: !locked,
      draggable: !draggable, 
    });
  };

  return (
    <div
      style={{
        display: 'flex',
        gap: 8,
        borderBottom: '1px solid #ccc',
        padding: 8,
        alignItems: 'center',
      }}
    >
      {/* Если элемент – текст, показываем поля */}
      {selectedElement.type === 'text' && (
        <>
          <span>Текст:</span>
          <Input
            style={{ width: 120 }}
            value={textValue}
            onChange={(e) => {
              setTextValue(e.target.value);
              handleChangeText({ text: e.target.value });
            }}
          />

          <span>Шрифт:</span>
          <Select
            style={{ width: 120 }}
            value={fontFamily}
            onChange={(val) => {
              setFontFamily(val);
              handleChangeText({ fontFamily: val });
            }}
            options={[
              { label: 'Arial', value: 'Arial' },
              { label: 'Times New Roman', value: 'Times New Roman' },
              { label: 'Courier New', value: 'Courier New' },
            ]}
          />

          <span>Размер:</span>
          <Input
            type="number"
            style={{ width: 60 }}
            value={fontSize}
            onChange={(e) => {
              const num = Number(e.target.value);
              setFontSize(num);
              handleChangeText({ fontSize: num });
            }}
          />

          <span>Цвет:</span>
          <Input
            type="color"
            style={{ width: 40 }}
            value={fillColor}
            onChange={(e) => {
              setFillColor(e.target.value);
              handleChangeText({ fill: e.target.value });
            }}
          />
        </>
      )}

      {/* Дубликат */}
      <Button onClick={() => onDuplicateElement(selectedElement.id)}>
        <CopyOutlined />
      </Button>

      {/* Слой вверх/вниз */}
      <Button onClick={() => onMoveUpOne(selectedElement.id)}>
        <UpOutlined />
      </Button>
      <Button onClick={() => onMoveDownOne(selectedElement.id)}>
        <DownOutlined />
      </Button>

      {/* Замок */}
      <Button onClick={toggleLock}>
        {locked ? <LockOutlined /> : <UnlockOutlined />}
      </Button>

      {/* Удалить */}
      <Button type="primary" danger onClick={() => onDeleteElement(selectedElement.id)}>
        <DeleteOutlined />
      </Button>
    </div>
  );
}

export default TopPanel;
