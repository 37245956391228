import urls from "../../config/Config";

export const fetchDeleteConfigurator = async (id, callback) => {
    let token = localStorage.getItem('token');
    try {

        const response = await fetch(`${urls.API_BASE_URL}/configurator/${id}`, {

            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        });
        console.log(response);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        console.log('Response from Backend:', data);
        callback(data)

    } catch (error) {
        callback(false)
        console.error('Error during fetch:', error);
    }
};